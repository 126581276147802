import { sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';

import type { TrackingContext } from 'src/types/TrackingContext';

interface PartnerTracking extends TrackingContext {
  numberOfPartners: number;
  partnerIndex: number;
  partnerName: string;
  partnerUrl: string;
}

export const trackLinkClick = (
  e: React.MouseEvent<HTMLAnchorElement>,
  {
    numberOfPartners,
    partnerIndex,
    partnerName,
    partnerUrl,
    trackingPosition,
    trackingTitle,
  }: PartnerTracking,
) => {
  const formattedTrackingTitle = trackingTitle.toLowerCase();
  sendGALinkClickEvent(e, {
    event: 'custom_event',
    event_action: 'Partner storefront click',
    event_category: `${trackingPosition} | Partner block`,
    event_label: `${trackingPosition} | ${formattedTrackingTitle} | ${
      partnerIndex + 1
    } | ${partnerName}`,
    event_name: 'select_content',
    event_details: `partner storefront click`,
    content_position: `${trackingPosition}`,
    content_type: `partner block|${formattedTrackingTitle}`,
    total_options: `${numberOfPartners}`,
    selected_position: `${partnerIndex + 1}`,
    item_id: `${partnerName}`,
    partner: `${partnerName}`,
    destination_URL: `${partnerUrl}`,
  });
};

import type { PartnerProduct } from '@noths/polaris-client-ribbons-design-system';
import type { CurrencyCode } from '@noths/polaris-dev-ts-types';

import type { CatalogServiceProductData } from 'src/services/catalog/types';

export const productModelToPartnerProduct = (
  {
    images,
    isNew,
    links,
    onSale,
    preSalePrices,
    prices,
    purchasable,
    salePercentage,
    title,
  }: CatalogServiceProductData,
  currency: CurrencyCode,
): PartnerProduct => {
  const priceByCurrency = prices.find((price) => price.currency === currency);
  /* istanbul ignore next */
  const preSalePriceByCurrency = preSalePrices?.find((price) => price.currency === currency);

  return {
    alt: '',
    href: links[0]?.href /* istanbul ignore next */ || '',
    isNew: isNew,
    onSale: onSale,
    salePercentage: salePercentage,
    price: priceByCurrency,
    purchasable: purchasable,
    preSalePrice: preSalePriceByCurrency,
    src: images[0]?.href /* istanbul ignore next */ || '',
    title: title,
  };
};
